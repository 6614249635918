
@font-face {
  font-family: MoonRegular;
  src: url("./Moon2.0-Regular.otf");
}

@font-face {
  font-family: MoonBold;
  src: url("./Moon2.0-Bold.otf");
}

@font-face {
  font-family: HKNova;
  src: url("./HKNova-Medium.otf");
}

canvas {
  float: left;
  margin-left: 0px;
  margin-bottom: 60px;
  margin-top: 20px;
}

@media screen and (max-width: 800px) {
  canvas {
    float: none;
    display:block;
    /*margin: 20px auto 80px auto !important;*/
    margin: 20px auto 80px auto !important;
    padding-left:16px;
  }

  body{
  }
}

@keyframes glitch {
  0% {
    transform: translate(0)
  }
  20% {
    transform: translate(-5px, 5px)
  }
  40% {
    transform: translate(-5px, -5px)
  }
  60% {
    transform: translate(5px, 5px)
  }
  80% {
    transform: translate(5px, -5px)
  }
  to {
    transform: translate(0)
  }
}




